import React, { useRef, useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import SurveyTextInput from './SurveyTextInput';
import { useTranslation } from 'react-i18next';
import PushButton from '../helpers/PushButton';
import useHttp from '../hooks/useHttp';
import { getUrlParameters, createCookieIfNotExists, generateUserId } from '../helpers/Utils';

const Survey = (props) => {

    const { isLoading, error, sendRequest } = useHttp();
    const [httpError, setHttpError] = useState("")
    const [showIsLoading, setShowIsLoading] = useState(false)
    const [ckSubscriberId, setCkSubscriberId] = useState("")
    const { t } = useTranslation();
    const bookieNames = ["bet365", "bwin", "betway", "betfair"];
    const [selectedBookie, setSelectedBookie] = useState("");
    const [isValid, setIsValid] = useState(true);
    const answer1Ref = useRef();
    const answer2Ref = useRef();
    const answer3Ref = useRef();
    const bookie1Ref = useRef();
    const bookie2Ref = useRef();
    const bookie3Ref = useRef();
    const bookie4Ref = useRef();


    useEffect(() => {
        let param_dict = getUrlParameters()
        const ck_subscriber_id = param_dict["ck_subscriber_id"]
        setCkSubscriberId(ck_subscriber_id)

        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},   
            body: {
                service_type : "user_data_update_email_confirmed",
                user_id : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
                ck_subscriber_id : ck_subscriber_id,
            },
            onSuccessFunction : (responseJson) => {
                console.log("Survey.js - EMail Confirmation response from backend")
                console.log(JSON.stringify(responseJson))
            }
        };
        sendRequest(requestConfig);
    }, [sendRequest]);


    const bookieBtnPressedCallback = (name) => {
        setSelectedBookie(name);
        bookie1Ref.current.setIsPressed(name === bookieNames[0]);
        bookie2Ref.current.setIsPressed(name === bookieNames[1]);
        bookie3Ref.current.setIsPressed(name === bookieNames[2]);
        bookie4Ref.current.setIsPressed(name === bookieNames[3]);
    };


    const evaluateAndSend = () => {
        setHttpError("")
        const allValid = answer1Ref.current.isValid() && answer2Ref.current.isValid() && answer3Ref.current.isValid() && bookieNames.includes(selectedBookie);
        setIsValid(allValid);

        if (allValid) {
            let urlParams = getUrlParameters()
            setShowIsLoading(true)
            const requestConfig = {
                url : window.baseURL + "data_service",
                method : "POST",
                headers : { "Content-Type" : "application/json" },
                body: { 
                    "service_type": "survey_and_bookie", 
                    "iso" : urlParams.iso ? urlParams.iso : "unkown", 
                    "bookie" : selectedBookie,
                    "question1" : t("survey_js__question_1_title"),
                    "answer1" : answer1Ref.current.getText(),
                    "question2" : t("survey_js__question_2_title"),
                    "answer2" : answer2Ref.current.getText(),
                    "question3" : t("survey_js__question_3_title"),
                    "answer3" : answer3Ref.current.getText(),
                    "ck_subscriber_id" : ckSubscriberId,
                    "user_id" : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
                },
                onSuccessFunction : (responseJson) => {
                    console.log(JSON.stringify(responseJson))
                    if(responseJson.result === "success") {
                        // redirect client:
                        window.location.href = responseJson.al
                    } else if(responseJson.result === "failed") {
                        window.location.href = `${window.baseURL}affiliate-link-not-found?bn=${responseJson.elp.bn}&ci=${responseJson.elp.iso}` 
                    } else {
                        setHttpError(error)
                        setShowIsLoading(false)
                    }
                }
            };
            console.log(JSON.stringify(requestConfig))
            sendRequest(requestConfig);
        } else {
            console.log("Nothing has been sent to the server.")
        }
    };

    const updateIsValidCallback = (isValidParam) => {
        setIsValid(isValidParam)
    }

    return (
        <Container className="px-3 pt-3 pb-5">
            <h1 className="white">{t("survey_js__title")}</h1>
            <p className="white">{t("survey_js__explanation")}</p>
            <div className="mt-3"></div>
            
            <SurveyTextInput ref={answer1Ref} labeltext={t("survey_js__question_1_title")} updateIsValidCallback={updateIsValidCallback}/>
            <div className="mt-3"></div>
            
            <SurveyTextInput ref={answer2Ref} labeltext={t("survey_js__question_2_title")} updateIsValidCallback={updateIsValidCallback} />
            <div className="mt-3"></div>
            
            <SurveyTextInput ref={answer3Ref} labeltext={t("survey_js__question_3_title")} updateIsValidCallback={updateIsValidCallback} />
            <div className="mt-3"></div>
            
            <p className="white">{t("survey_js__choose_bookmaker")}</p>
            <div className="d-flex flex-row flex-wrap justify-content-evenly align-items-center m-0 p-0">
                <p className="white p-0 m-0">{t("survey_js__bet365_pushbutton_rating_text")}</p>
                <PushButton ref={bookie1Ref} btnName={bookieNames[0]} divStyle="survey_js__pushbtn_div m-2" imgStyle="survey_js__pushbtn_img"
                            imgPath={`images/bookie_logos/${bookieNames[0]}_logo.png`} btnPressedCallback={bookieBtnPressedCallback} />
                
                <p className="white p-0 m-0">{t("survey_js__bwin_pushbutton_rating_text")}</p>
                <PushButton ref={bookie2Ref} btnName={bookieNames[1]} divStyle="survey_js__pushbtn_div m-2" imgStyle="survey_js__pushbtn_img" 
                            imgPath={`images/bookie_logos/${bookieNames[1]}_logo.png`} btnPressedCallback={bookieBtnPressedCallback} />
                
                <p className="white p-0 m-0">{t("survey_js__betway_pushbutton_rating_text")}</p>
                <PushButton ref={bookie3Ref} btnName={bookieNames[2]} divStyle="survey_js__pushbtn_div m-2" imgStyle="survey_js__pushbtn_img"
                            imgPath={`images/bookie_logos/${bookieNames[2]}_logo.png`} btnPressedCallback={bookieBtnPressedCallback} />
                

                <div style={{display : "none"}}>
                <PushButton ref={bookie4Ref} btnName={bookieNames[3]} divStyle="survey_js__pushbtn_div m-2" imgStyle="survey_js__pushbtn_img"
                            imgPath={`images/bookie_logos/${bookieNames[3]}_logo.png`} btnPressedCallback={bookieBtnPressedCallback} />
                </div>
            </div>
            <p className="white">{t("survey_js__next_step_register")}</p>
            <p className="white text-center p-0 mb-0">{t("survey_js__register_btn_registration_required_text")}</p>
            { (!isLoading && !showIsLoading) && 
                <div className="d-flex justify-content-center align-items-center">
                    <Button className="warning btn-lg width_95 mt-2 submit_gradient_btn" disabled={selectedBookie.length === 0 || !isValid} onClick={evaluateAndSend}>
                        {selectedBookie.length === 0 ? t("survey_js__register_btn_blank_text") : t("survey_js__register_btn_text", { parameter: selectedBookie })}
                    </Button>
                </div>
            }
            { (isLoading || showIsLoading) && 
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status" style={{ color: 'yellow', width: '3rem', height: '3rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            {0 < httpError.length && <p className="survey_js__error-p text-center mt-2">{httpError}</p>}
            {!isValid && <p className="survey_js__error-p text-center mt-2">{t("survey_js__repair_errors")}</p>}
        </Container>
    );
};

export default Survey;

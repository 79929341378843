import Container from 'react-bootstrap/Container';
import EmailInput from './../email/Email-Input';
import "./../css/styles.css";
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { getBaseUrl, createCookieIfNotExists, generateUserId } from './../helpers/Utils';
import useHttp from '../hooks/useHttp';

const LandingPage1 = () => {
    
    let { sendRequest } = useHttp()
    const { t } = useTranslation();
    const [topSpacerHeight, setTopSpacerHeight] = useState("20vh")

    const recalculateTopSpacerHeight = ( img ) => {
        let ratio = img.height/img.width
        // The image has 100% width of the viewport. So the height becomes:
        let calculatedImgHeight = window.innerWidth * ratio
        setTopSpacerHeight( 0.75*calculatedImgHeight + "px")
    }

    useEffect(() => {
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},   
            body: {
                user_id : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
                log_event : "lp1_page_called",
                service_type : "remote_logging",
            },
            onSuccessFunction : (responseJson) => {}
        };
        sendRequest(requestConfig);

        const img = document.createElement('img');  
        img.src = `${getBaseUrl()}/images/top_background.avif`;
        img.onload = () => { recalculateTopSpacerHeight(img) };
        return () => {  img.onload = null; };
    }, [sendRequest]);
  

    return (
        <Fragment>
            <Container className="lp_v1_top_container p-0 pb-4 white">
                <div className="top_background_img d-flex flex-column justify-content-center align-items-center" style={ { "backgroundImage" : `url(${ getBaseUrl() + "/images/top_background.avif" })` } }>
                    <div className="lp_v1_top_spacer" style={ { "height" : topSpacerHeight } }></div>
                    <h1 className="lp_v1_main_header Raleway-900 black_outline">{t("lp_v1_header")}</h1>
                    <h2 className="text-center Raleway-900 px-4 black_outline">{t("lp_v1_sub_header")}</h2>
                    <EmailInput submit_btn_text="lp_v1_submit_btn_send_me_strategy"/>
                </div>
            </Container>

            <Container className="lp_v1_middle_container white Raleway-400 m-0 p-0">
                <Container className="w95 mx-auto">
                    <h1 className="Raleway-900 mt-4">{t("lp_v1_what_header")}</h1>
                    <p>{t("lp_v1_hook_text_1")}</p>
                    <EmailInput submit_btn_text="lp_v1_submit_btn_send_me_strategy"/>
                    
                    <h1 className="Raleway-900 mt-5">{t("lp_v1_trusted_partner")}</h1>
                    <div className="mx-auto w-100 lp_v1_bookie_container_div d-flex flex-row flex-wrap justify-content-evenly align-items-center m-0 p-0">
                        <Image src="images/bookie_logos/bet365_logo.png" className="lp_v1_bookie_logo"/>
                        <Image src="images/bookie_logos/betway_logo.png" className="lp_v1_bookie_logo"/>
                        <Image src="images/bookie_logos/tipico_logo.png" className="lp_v1_bookie_logo"/>
                        <Image src="images/bookie_logos/bet3000_logo.png" className="lp_v1_bookie_logo"/>
                    </div>
                
                    <h1 className="Raleway-900 mt-5">{t("lp_v1_benefits_header")}</h1>
                    <ul>
                        <li>{t("lp_v1_li_proven_strategy")}</li>
                        <li>{t("lp_v1_li_step_by_step")}</li>
                        <li>{t("lp_v1_li_autogenerate_money")}</li>
                    </ul>   
                </Container>

                <Container className="p-0 mt-5">
                    <div className="lp_v1_emotional_benefit_div_1 m-0 w-100">
                        <div className="px-3 py-2">
                            <h1 className="Raleway-900">{t("lp_v1_impress_others_header")}</h1>
                            <p className="m-0 p-0">{t("lp_v1_impress_others_text")}</p>
                        </div>
                    </div>

                    <div className="lp_v1_emotional_benefit_div_2 m-0 w-100">
                        <div className="px-3 py-2">
                            <h1 className="Raleway-900">{t("lp_v1_create_income_stream_header")}</h1>
                            <p className="m-0 p-0">{t("lp_v1_create_income_stream_text")}</p>
                        </div>
                    </div>

                    <div className="lp_v1_emotional_benefit_div_3 m-0 w-100 bottom-shadow">
                        <div className="px-3 py-2">
                            <h1 className="Raleway-900">{t("lp_v1_secure_and_independent_life_header")}</h1>
                            <p className="m-0 p-0">{t("lp_v1_secure_and_independent_life_text")}</p>
                        </div>
                    </div>
                </Container>
                
                <h1 className="Raleway-900 mt-5 text-center">{t("lp_v1_testimonials_header")}</h1>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <Image src="images/turkish_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_1_name")}</p>
                    <p className="m-0 p-0 w80">{t("lp_v1_testimonial_1")}</p>
                    <Image src="images/arabic_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_2_name")}</p>
                    <p className="m-0 p-0 w80">{t("lp_v1_testimonial_2")}</p>
                    <Image src="images/german_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_3_name")}</p>
                    <p className="m-0 p-0 w80">{t("lp_v1_testimonial_3")}</p>
                </div>

                <Container className="w95 mx-auto">
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="d-flex justify-content-center align-items-center shadow bg_01_white py-4 px-3">
                            <div className="d-flex justify-content-center align-items-center flex-grow-1" style={{ flexBasis: '25%' }}>
                                <Image src="images/play_logo.avif" className="lp_v1_google_play_logo"/>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1" style={{ flexBasis: '75%' } }>
                                <h1 className="Raleway-900 p-0 pb-1 m-0 fs_150 text-center">{t("lp_v1_google_play_users_trust_us")}</h1>
                                <p className="m-0 p-0 pb-1 fs_110">{t("lp_v1_google_play_our_rating")}<span className="Raleway-900 fs_125 ps-2">{t("lp_v1_google_play_rating")}</span></p>
                                <Image src="images/rating_stars.avif" className="lp_v1_rating_stars m-0 p-0"/>
                            </div>
                        </div>
                    </div>

                    <h1 className="Raleway-900 mt-5 text-center">{t("lp_v1_final_chance_for_signup_cta")}</h1>
                    <p className="text-center">{t("lp_v1_we_do_not_sell_anything")}</p>
                    <p className="text-center">{t("lp_v1_no_spam_unsubscribe_anytime")}</p>
                    <EmailInput submit_btn_text="lp_v1_submit_btn_send_me_strategy"/>
                    {/* <LanguageSwitcher/> */}
                </Container>
                <div className="lp_v1_bottom_spacer"></div>
            </Container>

        </Fragment>
    )
}

export default LandingPage1

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import "./../css/styles.css";

const PushButton = forwardRef( ({btnName, divStyle, imgStyle, imgPath, btnPressedCallback}, ref) => {
  
    const [isPressed, setIsPressed] = useState(false);
    useImperativeHandle(ref, () => ({
        setIsPressed(state) { setIsPressed(state) },
    }));

    const handleButtonClick = () => { 
        btnPressedCallback(btnName)
        setIsPressed(!isPressed);
    };

    return (
        <div className={ divStyle ? divStyle + " PushButton_div" : "PushButton_div" } style={ { display : "inline-block" } }>
            <Button onClick={handleButtonClick} className={`PushButton_btn ${isPressed ? 'pressed' : ''}`} variant="None" >
                <Image className={imgStyle ? imgStyle + "" : ""} src={imgPath}/>
            </Button>
        </div>
    );
});

export default PushButton;

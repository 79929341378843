import Container from 'react-bootstrap/Container';
import EmailInput from '../email/Email-Input';
import "./../css/styles.css";
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './../helpers/Language-Switcher';
import { Image } from 'react-bootstrap';
import { getBaseUrl } from './../helpers/Utils';

const LandingPageSafe = () => {

    const { t } = useTranslation();
    const [topSpacerHeight, setTopSpacerHeight] = useState("20vh")

    const recalculateTopSpacerHeight = ( img ) => {
        let ratio = img.height/img.width
        // The image has 100% width of the viewport. So the height becomes:
        let calculatedImgHeight = window.innerWidth * ratio
        setTopSpacerHeight( 0.75*calculatedImgHeight + "px")
    }

    useEffect(() => {
        const img = document.createElement('img');  
        img.src = `${getBaseUrl()}/images/top_background.avif`;
        img.onload = () => { recalculateTopSpacerHeight(img) };
        return () => {  img.onload = null; };
    }, []);

    return (
        <Fragment>
            <Container className="lp_v1_top_container p-0 pb-4 white">
                <div className="top_background_img d-flex flex-column justify-content-center align-items-center" style={ { "backgroundImage" : `url(${ getBaseUrl() + "/images/top_background.avif" })` } }>
                    <div className="lp_v1_top_spacer" style={ { "height" : topSpacerHeight } }></div>
                    <h1 className="lp_v1_main_header Raleway-900 black_outline">{t("lps_header")}</h1>
                    <h2 className="text-center Raleway-900 px-4 black_outline">{t("lps_sub_header")}</h2>
                    <EmailInput submit_btn_text="lps_submit_btn_send_me_strategy"/>
                </div>
            </Container>

            <Container className="lp_v1_middle_container white Raleway-400 m-0 p-0">
                <Container className="w95 mx-auto">
                    <h1 className="Raleway-900 mt-4">{t("lps_what_header")}</h1>
                    <p>{t("lps_hook_text_1")}</p>
                    <EmailInput submit_btn_text="lps_submit_btn_send_me_strategy"/>
                    
                    <h1 className="Raleway-900 mt-5">{t("lps_benefits_header")}</h1>
                    <ul>
                        <li>{t("lps_li_proven_strategy")}</li>
                        <li>{t("lps_li_step_by_step")}</li>
                        <li>{t("lps_li_autogenerate_money")}</li>
                    </ul>   
                </Container>

                <h1 className="Raleway-900 mt-5 text-center">{t("lp_v1_testimonials_header")}</h1>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <Image src="images/turkish_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_1_name")}</p>
                    <p className="m-0 p-0 w80">{t("lps_testimonial_1")}</p>
                    <Image src="images/arabic_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_2_name")}</p>
                    <p className="m-0 p-0 w80">{t("lps_testimonial_2")}</p>
                    <Image src="images/german_guy_1.jpg" className="lps_testimonial_circle mt-3" roundedCircle />
                    <p className="m-0 p-0 mt-2">{t("lp_v1_testimonial_3_name")}</p>
                    <p className="m-0 p-0 w80">{t("lps_testimonial_3")}</p>
                </div>

                <Container className="w95 mx-auto">
                    <h1 className="Raleway-900 mt-5 text-center">{t("lps_final_chance_for_signup_cta")}</h1>
                    <p className="text-center">{t("lps_we_do_not_sell_anything")}</p>
                    <EmailInput submit_btn_text="lps_submit_btn_send_me_strategy"/>
                    <LanguageSwitcher/>
                </Container>
                <div className="lp_v1_bottom_spacer"></div>
            </Container>
        </Fragment>
    )
}

export default LandingPageSafe

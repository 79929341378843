import React from 'react';
import { useTranslation } from 'react-i18next';
import './../css/styles.css';

const LanguageSwitcher = () => {

    const { t, i18n } = useTranslation();
  
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className="language_switcher_div">
            <p className="p-0 m-0">{t("language_switcher_js__change_language")}</p>
            <button className="language_switcher_button" onClick={() => changeLanguage('en')}>🇬🇧</button>
            <button className="language_switcher_button" onClick={() => changeLanguage('de')}>🇩🇪</button>
        </div>
    );
}

export default LanguageSwitcher;

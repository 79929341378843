import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SurveyTextInput = forwardRef(({ labeltext, updateIsValidCallback }, ref) => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useImperativeHandle(ref, () => ({
        getText() { return value; },
        isValid() { return handleBlur(); }
    }));

    const handleBlur = () => {
        const wordCount = value.trim().split(/\s+/).length;
        if (wordCount < 3) {
            setIsError(true);
            setErrorMessage(t("SurveyTextInput_js__answer_too_short_error"));
            updateIsValidCallback(false)
            return false;
        } else {
            setIsError(false);
            setErrorMessage('');
            updateIsValidCallback(true)
            return true;
        }
    };

    const handleFocus = () => {
        setIsError(false);
    };

    return (
        <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label className="white">{labeltext}</Form.Label>
        <Form.Control
            as="textarea"
            rows={3}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={{ color: isError ? "white" : "black",  backgroundColor: isError ? '#ff0033' : 'white' }}
        />
        {isError && <Alert variant="danger" className="mt-2">{errorMessage}</Alert>}
        </Form.Group>
    );
});

export default SurveyTextInput;

import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import DateTimeFormatter from "../helpers/DateTimeFormatter"
import { Button } from "react-bootstrap"

const ValueBettingAnalysisRow = ({ match_dict }) => {

    const [resultType, setResultType] = useState("")
    const clubsLookup = useSelector(state => state.sd.clubs)
    const leaguesLookup = useSelector(state => state.sd.leagues)
    const [clubIconSize, setClubIconSize] = useState("80%")
    const [leagueIconSize, setLeagueIconSize] = useState("80%")
    const [leagueIconMarginTop, setLeagueIconMarginTop] = useState(0)
    const { t } = useTranslation()
    

    /*

    Wahrscheinlichkeiten für Bayern gewinnt:

    Deine Schätzung: 56%
    bet365: 43% 

    Deine Vermutung ist sicherer als die von bet365.
    D.h. es ist eine für Dich sicherere Wette mit der Du bet365 schlagen kannst: 
    [Jetzt Wette platzieren]

    ------------------()--------------

    Wahrscheinlichkeit für Schalke gewinnt:

    Deine Schätzung: 30%
    bet365: 56%

    Du bist Dir also zu 70% sicherer dass Leverkusen gewinnt oder unentschieden gespielt wird.

    D.h. Du solltest eine Gegenwette platzieren mit der Du bet365 schlagen kannst:
    [Jetzt Doppelte Chance-Gegenwette abgeben]

    ------------------()--------------------
    
    */

    useEffect( () => {
        let view_port_width = window.innerWidth
        const _clubIconSize = Math.floor(view_port_width/6.5)
        const _leagueIconSize = Math.floor(view_port_width/10.5)
        setClubIconSize( _clubIconSize + "px")
        setLeagueIconSize( _leagueIconSize + "px")
        setLeagueIconMarginTop( Math.floor( _clubIconSize - _leagueIconSize)/2 ) 
        setResultType( match_dict.n_probas[0] < (match_dict.probability/100.0) ? "Home" : "Alternative" ) 
    }, [match_dict])


    return (
        <div className="d-flex align-items-center justify-content-center flex-column w-100">
            <div className="d-flex align-items-center justify-content-center flex-column w95 py-3 mb-3 ValueBettingAnalysisRow_js__match_div">
                <DateTimeFormatter str_date={match_dict.datetime}/>
                
                <div className="w-100" style={{ position : "relative"}}>
                    <div className="d-flex align-items-center justify-content-evenly w-100" style={{ position: 'absolute', zIndex: 1, top : leagueIconMarginTop }}>
                        <img style={{ width: leagueIconSize, height: leagueIconSize }} src={`${window.baseURL}images/league_icons/${match_dict.league_id}.png`} alt={`${leaguesLookup[match_dict.league_id]} logo`} />
                    </div>
                    <div className="d-flex align-items-center justify-content-evenly w-100" style={{ position: 'relative', zIndex: 2 }}>
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: '50%' }}>
                            <img style={{ width: clubIconSize, height: clubIconSize }} src={`${window.baseURL}images/club_icons/${match_dict.club1_id}.png`} alt={`Club logo of ${match_dict.club1_id}`} />
                            <p className="p-0 pt-2 m-0 text-center">{clubsLookup[match_dict.club1_id]}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: '50%' }}>
                            <img style={{ width: clubIconSize, height: clubIconSize }} src={`${window.baseURL}images/club_icons/${match_dict.club2_id}.png`} alt={`Club logo of ${match_dict.club2_id}`} />
                            <p className="p-0 pt-2 m-0 text-center">{clubsLookup[match_dict.club2_id]}</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-center flex-column w-100">

                    <p className="p-0 m-0 mt-3 text-center">{t("ValueBettingAnalysisRow_js__estimate_title_p", { clubname : clubsLookup[match_dict.club1_id]})}</p>

                    <div className="d-flex align-items-center justify-content-center w-100">
                        <p className="p-0 pe-1 m-0 w50 text-end">{t("ValueBettingAnalysisRow_js__your_estimate_p")}</p>
                        <p className="p-0 ps-1 m-0 w50">{  match_dict.probability }%</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-center w-100">
                        <p className="p-0 pe-1 m-0 w50 text-end">bet365:</p>
                        <p className="p-0 ps-1 m-0 w50">{  Math.floor(match_dict.n_probas[0]*100) }%</p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column w-100 px-3">

                    <h3 className="p-0 m-0 mt-3 text-center">{t("ValueBettingAnalysisRow_js__analysis_result")}</h3>
                    { resultType === "Home" && 
                        <div className="d-flex w-100 flex-column">
                            <p className="p-0 m-0 mt-2 text-center">
                                { t("ValueBettingAnalysisRow_js__result_home_line_1", { percent : Math.floor( match_dict.probability - match_dict.n_probas[0]*100), clubname : clubsLookup[match_dict.club1_id], bookie_name : "bet365" }) }
                            </p>
                            <Button className="mt-3" variant="warning">{t("ValueBettingAnalysisRow_js__bet_home")}</Button>
                        </div>
                    }
                    { resultType === "Alternative" && 
                        <div className="d-flex w-100 flex-column">
                        <p className="p-0 m-0 mt-2 text-center">
                            { t("ValueBettingAnalysisRow_js__result_alternative_line_1", { percent : Math.floor( (100-match_dict.probability) - (100-match_dict.n_probas[0]*100) ), clubname : clubsLookup[match_dict.club1_id], bookie_name : "bet365" }) }
                        </p>
                        <p className="p-0 m-0 mt-1 text-center">
                            { t("ValueBettingAnalysisRow_js__result_alternative_line_2", { clubname: clubsLookup[match_dict.club2_id]})}
                        </p>
                        <Button className="mt-3" variant="warning">{t("ValueBettingAnalysisRow_js__bet_alternative")}</Button>
                    </div>
                    }
                </div>

                
            </div>
        </div>)

}

export default ValueBettingAnalysisRow
import { Fragment, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./Email-Input.css";
import useHttp from '../hooks/useHttp';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createCookieIfNotExists, generateUserId } from "../helpers/Utils";

const EmailInput = (props) => {

    const { t } = useTranslation();

    let { sendRequest } = useHttp();
    let email_input_ref = useRef();
    const convertkit_form_ref = useRef()
    const convertkit_input_ref = useRef()
    let [email_is_valid, set_email_is_valid] = useState(true);
    let [email_error_msg, set_email_error_msg] = useState("");
    let [show_waiting_indicator, set_show_waiting_indicator] = useState(false)

    const validate_email = (email) => {
        // https://stackoverflow.com/a/48800/1020704
        const regexp = /^\S+@\S+\.\S+$/;
        if(email.length === 0) {
            set_email_error_msg(t("email_input_js__please_enter_valid_address"));
            set_email_is_valid(false);
            return false;
        }
        if(!regexp.test(email)) {
            set_email_error_msg(t("email_input_js__please_enter_valid_address"));
            set_email_is_valid(false);
            return false;
        }
        set_email_error_msg("");
        set_email_is_valid(true);
        return true;
    }

    const on_email_change_handler = (event) => {
        set_email_error_msg("");
        set_email_is_valid(true);
    }

    const on_email_blur_handler = (event) => {
        validate_email(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const email = email_input_ref.current.value;
        const email_validation_result = validate_email(email);
        if(email_validation_result) {
            const requestConfig = {
                url : window.baseURL + "newslettersignup",
                method : "POST",
                headers : { "Content-Type" : "application/json"},   
                body: {
                    email : email,
                    user_id : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
                    service_type : "email_signup",
                },
                onSuccessFunction : (responseJson) => {
                    if(responseJson.result === "success") {
                        convertkit_input_ref.current.value = email;
                        convertkit_form_ref.current.submit();
                        //navigate("/thank-you-nov-2023-v1");
                    } 
                    //set_show_waiting_indicator(false)
                }
            };
            sendRequest(requestConfig);
            set_show_waiting_indicator(true)
        }
    }


    return (
        <Fragment>
            <Form className="email-form text-center width_95 mx-auto">
                <Form.Group className="">
                    <Form.Control className="mb-2 email-input width_95 mx-auto"
                                  ref={email_input_ref} type="email" placeholder={t("email_input_js__enter_address_to_get_strategy")} 
                                  onChange={on_email_change_handler} onBlur={on_email_blur_handler}/>
                    { !email_is_valid && <p className="email-error-p width_95 mx-auto">{ email_error_msg }</p> }
                </Form.Group>

                { !show_waiting_indicator && 
                    <Button variant="warning btn-lg width_95 mt-2 submit_gradient_btn" type="submit" onClick={submitHandler}>
                        {t(props.submit_btn_text ? props.submit_btn_text : "email_input_js__submit_btn_txt")}
                    </Button> }
                { show_waiting_indicator &&  <Spinner animation="border" className="custom-spinner" /> }
            </Form>

            <div style={{ display : "none" }}>
                <form ref={convertkit_form_ref} action="https://app.convertkit.com/forms/6856403/subscriptions" method="post">
                    <input ref={convertkit_input_ref} name="email_address" type="email"/>
                </form>
            </div>
        </Fragment>
    );
}

export default EmailInput;
import { createSlice  } from "@reduxjs/toolkit";

const initialSupportDataState = { 
                                    clubs : {},
                                    leagues : {}
                                };
const supportDataSlice = createSlice({
    name: "SupportDataSlice",
    initialState: initialSupportDataState,
    reducers: {
        updateClubsLookup(state, action) {
            state.clubs = action.payload
        },

        updateLeaguesLookup(state, action) {
            state.leagues = action.payload
        },
    }
});

export const supportDataActions = supportDataSlice.actions;
export default supportDataSlice;
import { useEffect, useState } from "react"
import { getUrlParameters } from "./helpers/Utils"
import { Container } from "react-bootstrap"

const AffiliateLinkNotFound = () => {

    const [bookieName, setBookieName] = useState("")
    const [countryIso, setCountryIso] = useState("")

    useEffect( () => {
        let params_dict = getUrlParameters()
        setBookieName(params_dict["bn"])
        setCountryIso(params_dict["ci"])
    }, [])

    return (
        <Container>
            <p className="mt-5 mx-5 p-4 text-center affiliateLinkNotFound_js__error_p">
                Error: <span className="font-weight-bold Raleway-900">{bookieName}</span> affiliate link for <span className="font-weight-bold Raleway-900">{countryIso}</span> not found
            </p>
        </Container>)
}

export default AffiliateLinkNotFound
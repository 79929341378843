import { configureStore } from "@reduxjs/toolkit";
import valueBettingSlice from "./ValueBettingSlice";
import supportDataSlice from "./SupportDataSlice";

const store = configureStore({
    reducer: {
        vb: valueBettingSlice.reducer,
        sd : supportDataSlice.reducer,
    }
});

export default store;
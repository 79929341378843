import { Spinner } from "react-bootstrap"

const LoadingIndicator = ({ loading_text }) => {

    return(
        <div className="d-flex align-items-center justify-content-center w-100 h-100 flex-column">
            <h1 className="white mb-3">{loading_text}</h1>
            <Spinner className="mb-5" variant="light"/>
        </div>)

}

export default LoadingIndicator
import { createSlice  } from "@reduxjs/toolkit";

const initialValueBettingState = { matches : [] };
const valueBettingSlice = createSlice({
    name: "ValueBetting",
    initialState: initialValueBettingState,
    reducers: {
        updateValueBettingMatches(state, action) {
            state.matches = action.payload
        },

        updateWinningProbability(state, action) {
            const { match_id, new_probability } = action.payload;
            const matchIndex = state.matches.findIndex(match => match.match_id === match_id);
            if (matchIndex !== -1) 
                state.matches[matchIndex].probability = new_probability;
        },
    }
});

export const valueBettingActions = valueBettingSlice.actions;
export default valueBettingSlice;
import Container from 'react-bootstrap/Container';
import React, { useEffect } from 'react';
import "./../css/styles.css";
import { useTranslation } from 'react-i18next';
import sendGTMEvent from "./../helpers/GoogleTagManger"
import { getUrlParameters } from '../helpers/Utils'; 
import useHttp from '../hooks/useHttp';
import { createCookieIfNotExists, generateUserId } from '../helpers/Utils';


const ThankYou1 = () => {

    const { t } = useTranslation();
    let { sendRequest } = useHttp();

    // Place this in your component where the conversion happens
    useEffect(() => {
        //Google Tag-Manager
        sendGTMEvent('email_signup_success', {});

        let param_dict = getUrlParameters()
        const ck_subscriber_id = param_dict["ck_subscriber_id"]
        const email = param_dict["email"]

        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},   
            body: {
                service_type : "user_data_update",
                email : email,
                user_id : createCookieIfNotExists("user_id", generateUserId() ), // Do not forget to always send the user_id along the request
                ck_subscriber_id : ck_subscriber_id,
            },
            onSuccessFunction : (responseJson) => {
                console.log("ThankYou-Page - user_data_update result:")
                console.log(JSON.stringify(responseJson))
            }
        };
        sendRequest(requestConfig);
    }, [sendRequest]);

    
    return (
        <Container className="mt-5 px-3 white">
            <h1>{t("thank_you__ty_signing_up")}</h1>
            <ul>
                <li>{t("thank_you__check_spam_folder")}</li>
                <li>{t("thank_you__check_email_now_cta")}</li>
                <li>{t("thank_you__unsubscribe_any_time")}</li>
            </ul>
            <p>{t("thank_you__thanks_trust")}</p>
        </Container>
    )
}

export default ThankYou1
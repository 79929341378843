import { useSelector } from "react-redux"
import ValueBettingAnalysisRow from "./ValueBettingAnalysisRow"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";

const ValueBettingAnalysis = () => {

    const navigate = useNavigate();
    const {t} = useTranslation()
    const matches = useSelector(state => state.vb.matches )

    return (
        <div className="d-flex align-items-center justify-content-center flex-column">
            <h1 className="white mt-3">{t("ValueBettingAnalysis_title")}</h1>
            <h1 className="white">{t("ValueBettingAnalysis_subtitle")}</h1>
            <p className="white text-center w-100 mt-2">{t("ValueBettingAnalysis_explanation")}</p>
            { matches.map((match, index) => ( <ValueBettingAnalysisRow key={index} match_dict={match} /> ))}
            { matches.length === 0 && 
                <Button variant="warning mt-5" onClick={ () => { navigate("/valuebetting"); } }>{t("ValueBettingAnalysis_restart")}</Button>
            }
        </div>)
}

export default ValueBettingAnalysis
import { parse, format } from 'date-fns';
import { enGB, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';


const DateTimeFormatter = ({str_date}) => {

    const { t, i18n } = useTranslation();
    const localeMap = {
        en: enGB, //en existiert nicht, alternativ z.B. enUS, enIN usw...
        de: de, 
    };

    const formatCustomDate = (date) => {
        const simple_iso_code = i18n.language.split('-')[0]
        const locale = localeMap[simple_iso_code] || enGB;
        return format(date, t('datetimeformatter_date_format'), { locale });
    };

    const formatCustomTime = (date) => {
        const simple_iso_code = i18n.language.split('-')[0]
        const locale = localeMap[simple_iso_code] || enGB;
        return format(date, t('datetimeformatter_time_format'), { locale });
    }

    /**
     * Formats a date string into a specified format.
     * @param {string} dateString - The date string to format (in "yyyy-mm-dd hh:mm" format).
     * @param {string} formatString - The format string to use for the output.
     * @returns {string} - The formatted date string.
     */
    const formatDateString = (dateString) => {
        return parse(dateString, 'yyyy-MM-dd HH:mm', new Date());
    };

    return (
        <p>
            { formatCustomDate(formatDateString(str_date)) }  
            {t("datetimeformatter_at")}
            { formatCustomTime(formatDateString(str_date)) }
            {t("datetimeformatter_h")}
        </p>)
}

export default DateTimeFormatter